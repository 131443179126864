import React, {useEffect, useRef, useState} from "react";
import Banner from "../Banner/Banner";
import UsualQuestions from "../UsualQuestions/UsualQuestions";
import "./Homepage.css";
import Loader from "../Loader/Loader";
import ProductsService from "../../services/ProductsService";
import ProductCards from "./ProductCards/ProductCards";
import Devices from "./Devices/Devices";
import ProductTabs from "./ProductTabs/ProductTabs";
import Preview from "./Preview/Preview";
import {LandingPageHelper} from "../Utilities/CmsHelpers";
import {useLocation} from "react-router-dom";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import Markdown from "react-markdown";
import {useLoading} from "../../context/LoadingContext";
import {useCms} from "../../context/CmsContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

function Homepage({goToProducts = false}) {
    const location = useLocation();
    const account = useAccount();

    const {isLoading, removeLoading, addLoading} = useLoading();
    const {loggedIn, authData} = useAuth();

    const [hasSubscription, setHasSubscription] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [packageData, setPackageData] = useState();
    const [avod, setAvod] = useState(false);
    const [freeProduct, setFreeProduct] = useState(false);
    const [productName, setProductName] = useState("");

    const {landingData} = useCms();
    const contextLanding = landingData;
    const bannerDataList = LandingPageHelper.getBannerSection(contextLanding);

    const [informationModal, setInformationModal] = useState([]);
    const [showInformationModal, setShowInformationModal] = useState(false);

    useEffect(()=>{
        setInformationModal(LandingPageHelper.getInformationModal(contextLanding))
    }, [contextLanding])

    useEffect(()=>{
        setShowInformationModal((!!informationModal?.length))
    }, [informationModal])

    const searchParams = new URLSearchParams(location.search);
    const promo = searchParams.get('promo');
    const contest = searchParams.get('contest');
    const [bannerData, setBannerData] = useState({})

    useEffect(() => {
        if (bannerDataList && bannerDataList.length) {
            if(!!promo){
                setBannerData(bannerDataList.find(item => (item.BannerType === "Promo" && item.identifier === promo)) ??
                    bannerDataList.find(item => (item.BannerType === "Default" && item.identifier === "default")) ??
                    bannerDataList[0])
            }else if(!!contest){
                setBannerData(bannerDataList.find(item => (item.BannerType === "Contest" && item.identifier === contest)) ??
                    bannerDataList.find(item => (item.BannerType === "Default" && item.identifier === "default")) ??
                    bannerDataList[0])
            }else{
                setBannerData(bannerDataList.find(item => (item.BannerType === "Default" && item.identifier === "default")) ??
                bannerDataList[0])
            }
        }
    }, [bannerDataList, promo, contest])

    useEffect(() => {
        account.setInHomepage(true);
        return () => {
            account.setInHomepage(false);
        };
    }, [account]);

    useEffect(() => {
        if(!(isLoading("INIT"))){
            addLoading("INIT")
        }
        if (loggedIn) {
                setHasSubscription(!!authData.productId);
                if (!!authData.productId) {
                    ProductsService.getProductReferenceStandalone({
                        productId: authData.productId,
                        pricePoint: authData.pricePointHandle,
                    })
                        .then((res) => {
                            setProductName(res.data.displayName);
                            setFreeProduct(res.data.displayName === "Lite");
                            setPackageData([...res.data.upgrades, ...res.data.downgrades.filter(downgrade => downgrade.displayName !== "Lite")]);
                            setAvod(res.data.upgrades.length > 0);
                        })
                        .catch(()=>{

                        })
                        .finally(()=>{
                            removeLoading("INIT")
                        })
                }else{//todo: ask when insufficient register, what to show?
                    setPackageData([])
                    removeLoading("INIT")
                }
        } else {
            if(loggedIn !== undefined){
                ProductsService.getProductsStandalone()
                    .then((response) => {
                        setPackageData(response.data.packages);
                    })
                    .catch(()=>{

                    })
                    .finally(()=>{
                        removeLoading("INIT")
                    })
            }
        }
    }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

    const productsRef = useRef();

    return (<>
            {(isLoading() || !(!!bannerData.title)) ? (<Loader/>) : (
                <div className="homepage">
                    {!!bannerData.title &&
                        <Banner
                            avod={avod}
                            hasSubscription={hasSubscription}
                            freeProduct={freeProduct}
                            data-scroll-id="section-1"
                            productsRef={productsRef}
                            productName={productName}
                            goToProducts={goToProducts}
                            upgrades={packageData}
                            bannerData={bannerData}
                            isContest={bannerData.BannerType === "Contest"}
                        />
                    }
                    {!(bannerData.BannerType === "Contest") &&
                        <>
                            <Preview/>

                            <ProductTabs/>

                            {!!packageData?.length &&
                                <div ref={productsRef}>
                                    <ProductCards products={packageData} data-scroll-id="section-2"/>
                                </div>
                            }

                            <Devices/>

                            <UsualQuestions/>

                            <div className="remind-sign-up">
                                <div className="wrapper">
                                    <img
                                        src="images/ant1Logo.png"
                                        alt="ant1 logo"
                                        id="homepage-logo"
                                    />
                                </div>
                            </div>
                        </>
                    }

                    <PopUp
                        openPopUp={showInformationModal}
                        setOpenPopUp={setShowInformationModal}
                        maxWidth="md"
                        showCloseButton={true}
                        outlined={false}
                        error={false}
                        closeWhenClickedOutside={true}
                    >
                        <GenericError
                            setClose={setShowInformationModal}
                            success={true}
                            mainText={informationModal[0]?.title}
                            buttonText={informationModal[0]?.buttonText}
                            showIcon={false}
                            executeFunction={() => window.open(informationModal[0]?.redirectURL, "_self")}>
                            <span className={"account-email-popup-secondary-first landing-informationmodal"} ><Markdown>{informationModal[0]?.description}</Markdown></span>
                        </GenericError>
                    </PopUp>
                </div>)}
        </>);
}

export default Homepage;
