import React, { useState, useEffect } from "react";
import "./Unsubscribe.css";
import { Button } from "@mui/material";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import AccountsService from "../../services/AccountsService";
import PopUp from "../Utilities/PopUp";
import GenericError from "../Utilities/GenericError";
import nbaPricePoints from "../../data/nbaPricePoints.json";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";

function Unsubscribe() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [genericError, setGenericError] = useState(false);
  const [periodEndDate, setPeriodEndDate] = useState("");
  const [nbaMessage, setNbaMessage] = useState(false);


  const {isLoading, removeLoading, addLoading} = useLoading();
  const {loggedIn, setCheckLoggedIn} = useAuth();

  const [userData, setUserData] = useState()

  const onCancel = (e) => {
    e.preventDefault();
    setCheckLoggedIn(false);
    navigate("/Account");
  };

  const onUnsubscribe = () => {
    if(!!userData){
      addLoading("HANDLEUNSUBSCRIBE");
      let subscriptionId = userData.subscription
        ? userData.subscription.id
        : -1;
      AccountsService.unsubscribeSubscription({ subscriptionId: subscriptionId })
        .then((response) => {
          navigate("/Account");
        })
        .catch((error) => {
          setGenericError(true);
        })
        .finally(() => {
          removeLoading("HANDLEUNSUBSCRIBE")
        });
    }else{//should never go here
      setGenericError(true);
    }
  };

  useEffect(() => {
    if(!(isLoading("INIT"))){
      addLoading("INIT")
    }
    if(loggedIn) {
      AccountsService.getAccounts()
          .then((response) =>{
            if(!!response.data.subscription){
              setUserData(response.data);
              handleNBAMessage(response.data.subscription.product.product_price_point_name)
              const tempdate =
                  response.data.subscription.current_period_ends_at.split("T");
              const date = new Date(tempdate[0]);
              setPeriodEndDate(
                  `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}/${
                      date.getMonth() < 9
                          ? "0" + (date.getMonth() + 1)
                          : date.getMonth() + 1
                  }/${date.getFullYear()}`
              );
            }
          })
          .catch(()=>{
            console.log("Error retrieving user information")
            setGenericError(true);
          })
          .finally(()=>{
            removeLoading("INIT")
          })
    }
  }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleNBAMessage = (product_price_point_name) => {
    let nbaItem = nbaPricePoints.find(
      (item) =>
        item.pricePointName === product_price_point_name
    );
    if (nbaItem) {
      setNbaMessage(nbaItem.message);
    }
  };

  return (
    <>
      {isLoading() ? (
        <Loader />
      ) : (
        <div className="unsubscribe-wrapper box big">
          <h3>{t("unsubscribeTitle")}</h3>
          {nbaMessage.length > 0 ? (
            <>
              <p>
                <strong>
                  {nbaMessage} Στην περίπτωση που ολοκληρώσεις την ακύρωση της συνδρομής σου,
                  θα χάσεις τους υπολοιπόμενους δωρεάν μήνες της προσφοράς.
                </strong>
              </p>

              <p>
                Η ακύρωση της συνδρομής θα πραγματοποιηθεί στις {periodEndDate}
                .&nbsp;
              </p>
            </>
          ) : (
            <p>
              {t("unsubscribeInfo1")}
              {periodEndDate}.&nbsp;
              <br className="desktop" />
              {t("unsubscribeInfo2")}
              <br />
              <br className="mobile" />
              {t("unsubscribeInfo3")}
            </p>
          )}

          <p>{t("unsubscribeInfo4")}</p>

          <ul>
            <li>{t("unsubscribeListItem1")}</li>
            <li>{t("unsubscribeListItem2")}</li>
            <li>{t("unsubscribeListItem3")}</li>
            <li>{t("unsubscribeListItem4")}</li>
          </ul>

          <div className="buttons">
            <Button variant="contained" color="primary" onClick={onUnsubscribe}>
              {t("unsubscribeButton")}
            </Button>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {t("cancelButton")}
            </Button>
          </div>
          <PopUp
            openPopUp={genericError}
            setOpenPopUp={setGenericError}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
          >
            <GenericError
              setClose={setGenericError}
              mainText={"Υπήρξε κάποιο πρόβλημα"}
              secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
            />
          </PopUp>
        </div>
      )}
    </>
  );
}

export default Unsubscribe;
