import "./ProductContainer.css"
import {useCms} from "../../../context/CmsContext";
import CmsHelper, {PickProgramPageHelper} from "../../Utilities/CmsHelpers";
import {useEffect, useRef} from "react";

export default function ProductContainer({product, isMultiMonthly, isSelected, setSelected, onNext, cancellationFlow}) {
    const {pickProgramData} = useCms();
    const programInfo = PickProgramPageHelper.getProgramInfo(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.DEFAULT));
    const cmsProgramData = PickProgramPageHelper.getProgram(programInfo, product.displayName);

    const productRef = useRef();
    useEffect(()=>{
        if(isSelected){
            if(productRef?.current){
                productRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                });
            }
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    return (cmsProgramData.filter(cmsData => cmsData.isMultiMonthly === isMultiMonthly).map((cmsData, index) => {
        return <div ref={productRef} key={index} className={`pick-product-wrapper ${isMultiMonthly ? "multiMonthly" : "monthly"} ${isSelected ? "isSelected" : ""}`}
                    id={product.displayName}
                    style={{backgroundColor: cmsData.displayName.colorCode + "26"}}
                    onClick={setSelected}>
            <div className={"pick-product-upper"}>
                <span className={"pick-product-title"}
                      style={{backgroundColor: cmsData.displayName.colorCode}}>{cmsData?.title}</span>
                <div className={"pick-product-prices"}>
                    {product.displayName === "Lite" ?
                        <span className={"pick-product-price-free"}>Δωρεάν</span>
                        :
                        <>
                            {(cmsData?.programOldPrice ||
                                    cmsData?.programOldPrice?.afterPrice ||
                                    cmsData?.programOldPrice?.beforePrice ||
                                    cmsData?.programOldPrice?.price) &&
                                <div className={"pick-product-old-price"}>
                                    {!!cmsData?.programOldPrice?.beforePrice &&
                                    <span
                                        className={"pick-product-old-price-before"}>{cmsData?.programOldPrice.beforePrice}</span>
                                    }
                                    {!!cmsData?.programOldPrice?.price &&
                                        <>
                                            <span
                                                className={"pick-product-old-price-price"}>{cmsData?.programOldPrice.price}</span>
                                            <div className={"crossed-line"} />
                                        </>
                                    }
                                    {!!cmsData?.programOldPrice?.afterPrice &&
                                    <span
                                        className={"pick-product-old-price-after"}>{cmsData?.programOldPrice.afterPrice}</span>
                                    }
                                </div>
                            }
                            {(cmsData?.programCurrentPrice ||
                                    cmsData?.programCurrentPrice?.afterPrice ||
                                    cmsData?.programCurrentPrice?.price) &&
                                <div className={"pick-product-price"}>
                                    {!!cmsData?.programCurrentPrice?.price &&
                                        <span
                                        className={"pick-product-price-price"}>{cmsData?.programCurrentPrice.price}</span>
                                    }
                                    {!!cmsData?.programCurrentPrice?.afterPrice &&
                                        <span
                                            className={"pick-product-price-before"}>{cmsData?.programCurrentPrice.afterPrice}</span>
                                    }
                                </div>
                            }
                            {!!cmsData?.SublineText &&
                                <span className={"pick-product-subline-text"}>{cmsData?.SublineText}</span>
                            }
                        </>
                    }
                </div>
            </div>
            <button className={"pick-product-cta"} onClick={onNext}>{cancellationFlow ? "Το θέλω!":"Συνέχεια"}</button>
            <div className={"pick-product-lower"}>
                <div className={"pick-product-usps"}>
                    <ul>
                        {!!cmsData.infUsps &&
                            <li>
                                Όλο το περιεχόμενο του <span
                                style={{color: cmsData?.infUsps?.colorCode}}>{cmsData?.infUsps?.Target_Product_Family}</span> προγράμματος
                            </li>
                        }
                        {!!cmsData.usps && cmsData.usps.usp.map((usp, index) => {
                            return <li key={index}>{usp.value}</li>
                        })}
                    </ul>
                </div>

                {!!cmsData?.plusText &&
                    <div className={"pick-product-plus-text"}>
                        <img src={"images/plus-sign.png"} alt={"ant1 plus sign"}/>
                        <span>{cmsData?.plusText}</span>
                    </div>
                }
            </div>
        </div>
    }))
}