import PinVerification from "../../PinVerification/PinVerification";
import AccountsService from "../../../services/AccountsService";
import PopUp from "../../Utilities/PopUp";
import React, {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {isValidPhoneNumber} from "react-phone-number-input/mobile";
import CardValidationService from "../../../services/CardValidationService";


export default function PhoneVerification({phoneNumberVerifyToken, triggerNextPopup, finallyAction}) {
    const [phoneNumberVerify, setPhoneNumberVerify] = useState(false);
    const [phoneNum, setPhoneNum] = useState();

    useEffect(()=>{
        const phoneNum = jwt_decode(JSON.parse(sessionStorage.getItem("kc_action")).token).phoneNumber;
        setPhoneNum(phoneNum);
        if(isValidPhoneNumber(phoneNum)){
            sessionStorage.setItem("cardValidationToken", phoneNumberVerifyToken)
            CardValidationService.generateOTP(
                {
                    phoneNumber: phoneNum,
                },
                phoneNumberVerifyToken
            )
                .then((response) => {
                    setPhoneNumberVerify(true);
                    sessionStorage.setItem("phoneVerificationToken", response.data.token);
                })
                .catch((error) => {
                    //todo error return from back
                    console.log("Failure: generateOTP")
                })
                .finally(()=>{
                    finallyAction();
                })
        }else{
            //should never go here
        }
    }, [phoneNumberVerifyToken]) //eslint-disable-line react-hooks/exhaustive-deps

    return (<PopUp
        openPopUp={phoneNumberVerify}
        setOpenPopUp={(state)=>{
            if(state === false){
                triggerNextPopup();
            }
            setPhoneNumberVerify(state)
        }}
        maxWidth="md"
        showCloseButton={true}
        outlined={false}
        error={false}
        closeWhenClickedOutside={false}
    >
        <PinVerification
            showBackButton={false}
            isModal={true}
            mobile={phoneNum}
            successAction={(token)=>{
                // addLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                AccountsService.patchUserForAction({phoneNumber: phoneNum, flowToken: token  })
                    .then((response) => {
                        setPhoneNumberVerify(false);
                        triggerNextPopup();
                        // navigate(0);
                    })
                    .catch((err) => {
                        setPhoneNumberVerify(false);
                        triggerNextPopup();
                    })
                    .finally(()=>{
                        // removeLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                    })
            }}
        />
    </PopUp>);
}